@import "~font-awesome/css/font-awesome.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
.container{
  font-family: "Barlow", Arial, Helvetica, sans-serif;
  font-size: 20px;
}

// ngx-datatable styling
// @import '~@swimlane/ngx-datatable/index.css';
// @import '~@swimlane/ngx-datatable/themes/bootstrap.css';
// @import '~@swimlane/ngx-datatable/themes/dark.css';
// @import '~@swimlane/ngx-datatable/assets/icons.css';

html, body { height: 100%;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }

@import "../src/assets/themes/Lux/custom/variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import '../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../node_modules/bootstrap/scss/bootstrap-grid';
@import "../src/assets/themes/Lux/custom/bootswatch";

legend, .col-form-label {
  font-weight: bold;
}

.form-control-plaintext {
  font-size: 18px;
}

